import React, { Component } from "react"
//import ReactDOM from "react-dom"
//import { Link } from "gatsby"

import Layout from "../../components/layout"

import SEO from "../../components/seo"
import { PageHeader, BannerHeader, BannerImage, styles } from "../../utils"
import gamesImg from "../../images/BGgamepads28.png"
import AboutImg from "../../images/Banner.png"
import { Link } from "gatsby"
import styled from "styled-components"
import HastyShamanInfo from "../../components/HomePageComponents/HastyShamanInfo"
const sizes = [
  { width: "640px", height: "360px", name: "small" },
  { width: "960px", height: "540px", name: "medium" },
  { width: "1280px", height: "720px", name: "large" },
]
class HastyShamanPage extends Component {
  constructor(props) {
    super(props)
    this.state = { gameSize: sizes[0] }
  }
  handleClick = value => {
    this.setState({ gameSize: value })
    console.log(value)
  }

  render() {
    return (
      <Layout>
        <SEO title="Hasty Shaman" />
        <BannerHeader>
          <Link
            to="https://bit.ly/3QJ35C5"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <BannerImage>
              <img src={AboutImg} alt="Super Choppy Orc"></img>
            </BannerImage>
          </Link>
        </BannerHeader>

        <PageHeader img={gamesImg}>
          <Paragrafos1 gameSize={this.state.gameSize}>
            {sizes.map(s => (
              <button
                style={{
                  letterSpacing: "5px",
                  position: "relative",
                  padding: "5px",
                  //left: "150px",
                  backgroundColor: "#161823",
                  //border: "5px solid red",
                  textIndent: "0.5rem",
                  margin: "10px 10px 5px 0px",
                  color: "white",
                }}
                onClick={() => this.handleClick(s)}
              >
                {s.name}{" "}
              </button>
            ))}

            <iframe
              src="https://elegant-villani-de01de.netlify.app/"
              scrolling="no"
              frameborder="0"
              title="dummy"
              style={{ height: "100%", width: "100%" }}
            ></iframe>
          </Paragrafos1>
        </PageHeader>
        <HastyShamanInfo />
      </Layout>
    )
  }
}

export default HastyShamanPage

const Paragrafos1 = styled.div`
  width: ${props => {
    return props.gameSize.width
  }};
  height: ${props => {
    return props.gameSize.height
  }};
  //border: 1px solid black;
  transition: all 1s;

  margin: 2rem auto;
  .text {
    line-height: 2rem;
    color: ${styles.colors.mainGrey};
    word-spacing: 2rem;
  }
`
